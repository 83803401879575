
import { Component, Vue, Prop } from "vue-property-decorator";
import {
  displaySpanRange,
} from "@/views/therapistCalendar/helpers";
import { post } from "@/api";
import { sessionDate } from '../../views/dashboard/visits/helpers'

@Component({
  methods: {
    displaySpanRange,
    sessionDate
  },
})
export default class VisitCancelPatient extends Vue {
  @Prop({})
  props: any;

  visitCanceled = false;

  informSessionToDelete() {
    this.$emit("session-to-delete", this.props.id);
  }

  async cancelSession() {
    try {
      await post.cancelSession(this.props.session.id);
      this.props.reloadSessions();
    } catch (error) {
      console.log(error);
    }
  }
}
