import i18n from "@/i18n";

export function getMonthsForLocale(
  monthLength: "numeric" | "2-digit" | "long" | "short" | "narrow",
  capitalizeFirstLetter: boolean
): Array<string> {
  const format = new Intl.DateTimeFormat(i18n.locale, {
    month: monthLength,
  });
  let months = [];
  for (let month = 0; month < 12; month++) {
    months.push(format.format(new Date(Date.UTC(1970, month, 1, 0, 0, 0))));
  }
  if (capitalizeFirstLetter) {
    months = months.map(
      (month: string) => month.charAt(0).toUpperCase() + month.slice(1)
    );
  } else {
    months = months.map((month: string) => month.toLowerCase());
  }
  return months;
}

export function getDaysForLocale(
  dayLength: "long" | "short" | "narrow",
  capitalizeFirstLetter: boolean
): Array<string> {
  const format = new Intl.DateTimeFormat(i18n.locale, {
    weekday: dayLength,
  });
  let days = [];
  for (let day = 0; day < 7; day++) {
    days.push(format.format(new Date(Date.UTC(1970, 1, day, 0, 0, 0))));
  }
  if (capitalizeFirstLetter) {
    days = days.map(
      (month: string) => month.charAt(0).toUpperCase() + month.slice(1)
    );
  } else {
    days = days.map((month: string) => month.toLowerCase());
  }
  return days;
}

export function displaySpanRange(span_start: number, span_end: number) {
  const formatter = Intl.DateTimeFormat("en-u-hc-h23", {
    hour: "2-digit",
    minute: "2-digit",
  });
  return (
    formatter.format(new Date(span_start)) +
    "-" +
    formatter.format(new Date(span_end))
  );
}
