import { Session } from "@/interfaces/therapy";
import {
    getMonthsForLocale,
    getDaysForLocale,
  } from "@/views/therapistCalendar/helpers";

export function dayNumber(span: number) {
    return new Date(span).getDate();
  }

export function monthName(span: number) {
    const monthNames = getMonthsForLocale("long", true);
    const date = new Date(span);
    return monthNames[date.getMonth()];
  }

export function dayName(span: number) {
    const weekdayNames = getDaysForLocale("short", true);
    const date = new Date(span);
    return weekdayNames[date.getDay()+1];
  }

export  function sessionDate(session: Session) {
    return (
      monthName(session.span.start) +
      " " +
      dayNumber(session.span.start) +
      ", " +
      dayName(session.span.start)
    );
  }